<template>
  <div>
    <form @submit.prevent="search" :class="isLoading ? 'is-busy' : ''">
      <div class="input-group">
        <input
          v-model.trim="form.keyword"
          ref="keyword"
          type="text"
          class="form-control"
          placeholder="Insert name or email address..."
        >
        <IconButton
          @click="search"
          :icon="'search'"
          :disabled="isSubmitDisabled"
          class="btn btn-primary"
        ></IconButton>
      </div>
    </form>

    <LoadingMessage v-if="isLoading"></LoadingMessage>

    <div v-if="results !== null" class="mt-3">
      <div v-if="results.length" class="list-group list-group-flush">
        <CoachListItem
          v-for="coach in results"
          :key="`key-${coach.userId}`"
          :user="coach"
          class="list-group-item"
        ></CoachListItem>
      </div>
      <div v-else class="m-5 text-center">No results found</div>
    </div>
  </div>
</template>

<script>
import { httpGet } from '@/core/http';

export default {
  name: 'SearchCoachForm',
  components: {
    IconButton: () => import('@/components/button/IconButton'),
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    CoachListItem: () => import('@/components/CoachListItem'),
  },
  methods: {
    async search() {
      this.isLoading = true;
      this.results = null;
      try {
        const res = await httpGet('/user/search', this.form);
        this.results = res.data;
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    isSubmitDisabled() {
      return this.form.keyword === '';
    },
  },
  data() {
    return {
      isLoading: false,
      form: {
        type: 'coach',
        keyword: '',
      },
      results: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.keyword.focus();
    });
  },
};
</script>
